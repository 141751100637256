<template>
  <b-card-actions action-collapse title="Daftar Konversi Harga">
    <!-- ref="refreshCard" -->
    <!-- action-refresh
    @refresh="refreshStop('refreshCard')" -->
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-tambah
          variant="primary"
          v-if="allowCreate()"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          Tambah
        </b-button>
        <b-modal
          v-if="allowCreate() || allowUpdate()"
          v-model="showModal"
          id="modal-tambah"
          cancel-variant="secondary"
          ok-title="Tambah"
          cancel-title="Batal"
          centered
          title="Form Tambah"
        >
          <b-form>
            <!-- <b-form-group>
              <label for="no">Nomor Urutan</label>
              <b-form-input v-model="form.no" id="gudang" placeholder="Nomor" type="number" />
            </b-form-group> -->
            <b-form-group>
              <label for="satuan">Pilih Satuan yang akan di konversikan</label>
              <b-form-select
                v-model="form.id_satuan"
                :options="id_satuan"
                label="satuan"
              />
            </b-form-group>
            <b-form-group>
              <label for="nilai">Nilai</label>
              <b-form-input
                v-model="form.nilai"
                id="gudang"
                placeholder="Nilai"
                type="number"
              />
            </b-form-group>
            <b-form-group>
              <label for="teritory">Pilih Barang</label>
              <b-form-select
                v-model="form.id_barang"
                :options="id_barang"
                label="barang"
              />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="success" @click="submit">
                Simpan
              </b-button>
              <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                Reset
              </b-button>
              <b-button size="sm" variant="danger" @click="showModal = false">
                Keluar
              </b-button>
            </section>
          </template>
        </b-modal>
        <b-modal
          v-if="allowUpdate()"
          v-model="showModalEdit"
          id="modal-ubah"
          cancel-variant="secondary"
          ok-title="Ubah"
          cancel-title="Batal"
          centered
          title="Form Ubah"
        >
          <b-form>
            <!-- <b-form-group>
              <label for="no">Nomor Urutan</label>
              <b-form-input v-model="form.no" id="gudang" placeholder="Nomor" type="number" />
            </b-form-group> -->
            <b-form-group>
              <label for="satuan">Pilih Satuan yang akan di konversikan</label>
              <b-form-select
                v-model="form.id_satuan"
                :options="id_satuan"
                label="satuan"
              />
            </b-form-group>
            <b-form-group>
              <label for="nilai">Nilai</label>
              <b-form-input
                v-model="form.nilai"
                id="gudang"
                placeholder="Nilai"
                type="number"
              />
            </b-form-group>
            <b-form-group>
              <label for="teritory">Pilih Barang</label>
              <b-form-select
                v-model="form.id_barang"
                :options="id_barang"
                label="barang"
              />
            </b-form-group>
          </b-form>
          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="info" @click="submit">
                Ubah
              </b-button>
              <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                Hapus isian
              </b-button>
              <b-button size="sm" variant="danger" @click="showModalEdit = false">
                Keluar
              </b-button>
            </section>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              size="sm"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button size="sm" :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          small
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(no)="row">
            <strong class="text-center">
              {{ row.index + 1 }}
            </strong>
          </template>
          <template #cell(id_barang)="data">
            {{ getBarangName(data.item) }}
          </template>

          <template #cell(actions)="row">
            <b-button
              v-if="allowUpdate()"
              v-b-tooltip.hover.right="'Ubah'"
              size="sm"
              @click="edit(row.item)"
              class="mr-1"
              variant="outline-info"
            >
              <feather-icon icon="EditIcon" /> </b-button
            ><b-button
              v-if="allowDelete()"
              v-b-tooltip.hover.right="'Hapus'"
              size="sm"
              @click="remove(row.item)"
              class=""
              variant="outline-danger"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import Ripple from "vue-ripple-directive";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions,
    ToastificationContent,
    VBTooltip,
    BModal,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showModalEdit: false,
      showModal: false,
      id: null,
      form: {
        id_barang: 0,
        nilai: 0,
        id_satuan: null,
      },
      id_barang: [],
      id_satuan: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        // { key: "id", label: "ID"},
        // { key: "nilai", label: "Nama Gudang", sortable: true },
        { key: "satuan.satuan", label: "dari satuan" },
        { key: "nilai", label: "Nilai" },
        { key: "id_barang", label: "Barang" },
        // { key: "barang.satuan.satuan", label: "ke satuan" },
        // { key: "barang.nama", label: "barang", sortable: true },
        // { key: "alamat", label: "alamat" },
        // { key: "keterangan", label: "Keterangan" },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.getData();
    // this.getDataDriver();
    // this.getDataSales();
    this.getDatabarang();
    this.getDatasatuan();
  },
  methods: {
    add() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModal = true;
      this.showModalEdit = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data gudang ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("konversi/save", [item])
            .then(() => {
              this.getData();
              this.pesanBerhasilHapus();
            })
            .catch((e) => {
              this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        id_barang: 0,
        nilai: 0,
        id_satuan: 0,
      };
    },
    submit() {
      if (this.form.nilai == null || this.form.nilai == "") {
        this.pesanGagalSimpan();
        return false;
      }
      if (this.id) {
        this.form.id = this.id;
      }
      const payload = this.form;
      this.$store
        .dispatch("konversi/save", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.getData();
          this.pesanBerhasilSimpan();
          this.resetForm();
          this.showModal = false;
          this.showModalEdit = false;
        })
        .catch((e) => console.error(e));
      // this.pesanGagalSimpan()
    },
    edit(item) {
      let { satuan, barang } = item;
      this.id = item.id;
      this.form = item;
      this.form.nilai = item.nilai;
      if (satuan) this.form.id_satuan = satuan.id;
      if (barang) this.form.id_barang = barang.id;
      this.showModal = true;

      // this.form = item;
      // this.id = item.id;
      // this.showModal = true;
      // this.id = item.id
      // this.form = item
      // this.showModalEdit = true
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getData() {
      this.$store
        .dispatch("konversi/getData", {})
        .then(() => {
          this.items = this.$store.state.konversi.datas;
          this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    getDatabarang() {
      this.$store
        .dispatch("barang/getDataV2", {})
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
          ter.map((item) => {
            item.value = item.id;
            item.text = item.nama + " - " + item.satuan.satuan;
          });
          this.id_barang = ter;
        })
        .catch((e) => console.error(e));
    },
    getDatasatuan() {
      this.$store
        .dispatch("satuan/getData", {})
        .then(() => {
          let ken = JSON.parse(JSON.stringify(this.$store.state.satuan.datas));
          ken.map((item) => {
            item.value = item.id;
            item.text = "1 " + item.satuan;
          });
          this.id_satuan = ken;
        })
        .catch((e) => console.error(e));
    },
    getBarangName(data) {
      let find = this.id_barang.find((item) => item.id == data.id_barang);
      return find ? find.satuan.satuan + " - " + find.nama : "-";
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
